<template>
  <EditingModal size="sm">
    <AlertDialog title="Add Stores" :onExit="close" :actions="actions">
      <v-container class="pa-0">
        <v-row class="mt-0 pb-3 pt-0"   align="center">
          <v-col cols="12" sm="2" class="label pa-3">Stores</v-col>
          <v-col cols="12" sm="9">
            <v-row
              
              
              align="center"
              class="store-row mt-0 pt-0 pb-1 pt-1"
              v-for="store in storesToAdd"
              :key="store.id"
            >
              <v-col cols="12" sm="9" class="label">{{store.display}}</v-col>
              <div class="row--delete" @click.stop="onDeleteStore(store)"></div>
            </v-row>
            <v-row class="mt-0 pb-3 pt-0"   align="center">
              <v-autocomplete
                v-if="addingStore"
                v-model="selectedStore"
                :items="stores"
                color="#93BD20"
                hide-no-data
                hide-selected
                item-text="display"
                item-value="id"
                placeholder="Start typing to lookup store"
                return-object
              ></v-autocomplete>
              <v-row
                v-else
                align="center"
                class="cursor pl-0 pb-3 pt-4"
                @click.stop="() => { addingStore = true }"
              >
                <div sm1 class="store-add-logo" />
                <div sm11 class="store">Add Store</div>
              </v-row>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-0 pt-1"   align="center">
          <v-col cols="12" sm="5" class="label pa-3">Permissions</v-col>
          <v-col cols="12" sm="2" class="header">Edit</v-col>
          <v-col cols="12" sm="2" class="header">View</v-col>
          <v-col cols="12" sm="2" class="header">No Access</v-col>
        </v-row>
        <v-row
          
          
          align="center"
          class="form-border-bottom mt-0 pt-0"
          v-for="[permission, access] in Object.entries(permissions)"
          :key="permission"
        >
          <v-col cols="12" sm="4" class="label__light pa-3 ml-5">{{ displayForPermission(permission) }}</v-col>
          <v-col cols="12" sm="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.EDIT"
              value
              @click.stop="setEdit(permission)"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.VIEW"
              @click.stop="setView(permission)"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.NONE"
              @click.stop="setNoAccess(permission)"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </AlertDialog>
  </EditingModal>
</template>

<script>
import EditingModal from "components/editing_modal";
import AlertDialog from "components/alert_dialog";
import { Permissions, Access } from "helpers/access";
import clone from "clone";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "AddStoresModal",
    components: {
        EditingModal,
        AlertDialog
    },
    data() {
        return {
            row: "",
            actions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: this.close
                },
                {
                    type: "proceed-green",
                    display: "Save",
                    run: this.save
                }
            ],
            permissions: {},
            selectedStore: undefined,
            addingStore: false,
            Access: Access,
            storesToAdd: []
        };
    },
    mounted() {
        this.permissions = Object.fromEntries(Object.entries(Permissions).map(([key, _]) => {
            return [key, Access.NONE]
        }))

        if (this.locations.length == 0) this.getAirports();
    },
    computed: {
        stores() {
            return this.locations.map(el => {
                return {
                    storeWaypointID: el.waypointID,
                    display: `${el.airportIdent} - ${el.name} - ${el.waypointID}`,
                    storeName: el.name,
                    airportIdent: el.airportIdent,
                    ...el,
                };
            });
        },
        ...mapGetters("AirportStore", ["locations"])
    },
    methods: {
        close() {
            this.$emit("close");
        },
        save() {
            this.$emit("save", {
                stores: this.storesToAdd,
                permissions: this.permissions
            });
        },
        accessForPermission(key) {
            return this.permissions[key];
        },
        displayForPermission(key) {
            return Permissions[key]
        },
        setNoAccess(permission) {
            this.$set(this.permissions, permission, Access.NONE);
        },
        setView(permission) {
            this.$set(this.permissions, permission, Access.VIEW);
        },
        setEdit(permission) {
            this.$set(this.permissions, permission, Access.EDIT);
        },
        onDeleteStore(store) {
            let index = this.storesToAdd.findIndex(el => el.id == store.id)
            if (index == -1) return
            this.storesToAdd.splice(index,1)
        },
        ...mapActions("AirportStore", ["getAirports"])
    },
    watch: {
        selectedStore(newValue, oldValue) {
            if (newValue == undefined) return;
            this.storesToAdd.push(this.selectedStore)
            this.selectedStore = undefined;
            this.addingStore = false;
    
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~scss/variables";
.form-border-bottom:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.store-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.label {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;

  &__light {
    font-weight: normal;
  }
}

.cursor {
  cursor: pointer;
}

.cursor .store {
  margin-top: 2px;
}
.store-add-logo {
  @include background-image("", "sublocation_add.svg", "../../img/");
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}

.store {
  color: $brand_color;
}

.row {
  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
}
</style>
