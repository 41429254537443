// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/sublocation_add.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".form-border-bottom[data-v-5936d116]:not(:last-child) {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;\n}\n.store-row[data-v-5936d116]:not(:last-child) {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;\n}\n.label[data-v-5936d116] {\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 16px;\n}\n.label__light[data-v-5936d116] {\n  font-weight: normal;\n}\n.cursor[data-v-5936d116] {\n  cursor: pointer;\n}\n.cursor .store[data-v-5936d116] {\n  margin-top: 2px;\n}\n.store-add-logo[data-v-5936d116] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  margin-left: 10px;\n  margin-right: 10px;\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}\n.store[data-v-5936d116] {\n  color: #93BD20;\n}\n.row--delete[data-v-5936d116] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}", ""]);
// Exports
module.exports = exports;
