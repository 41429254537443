var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-button d-flex align-items-center" }, [
    _vm.type !== "normal"
      ? _c("div", {
          staticClass: "text-button__icon",
          class: [`text-button__icon--${_vm.type}`],
          attrs: { id: "dontGrow" },
        })
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "text-button__text",
        class: [`text-button__text--${_vm.type}`],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }