var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditingModal",
    { attrs: { size: "sm" } },
    [
      _c(
        "AlertDialog",
        {
          attrs: {
            title: "Add Stores",
            onExit: _vm.close,
            actions: _vm.actions,
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "mt-0 pb-3 pt-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "label pa-3",
                      attrs: { cols: "12", sm: "2" },
                    },
                    [_vm._v("Stores")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "9" } },
                    [
                      _vm._l(_vm.storesToAdd, function (store) {
                        return _c(
                          "v-row",
                          {
                            key: store.id,
                            staticClass: "store-row mt-0 pt-0 pb-1 pt-1",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "label",
                                attrs: { cols: "12", sm: "9" },
                              },
                              [_vm._v(_vm._s(store.display))]
                            ),
                            _c("div", {
                              staticClass: "row--delete",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onDeleteStore(store)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-0 pb-3 pt-0",
                          attrs: { align: "center" },
                        },
                        [
                          _vm.addingStore
                            ? _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.stores,
                                  color: "#93BD20",
                                  "hide-no-data": "",
                                  "hide-selected": "",
                                  "item-text": "display",
                                  "item-value": "id",
                                  placeholder: "Start typing to lookup store",
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.selectedStore,
                                  callback: function ($$v) {
                                    _vm.selectedStore = $$v
                                  },
                                  expression: "selectedStore",
                                },
                              })
                            : _c(
                                "v-row",
                                {
                                  staticClass: "cursor pl-0 pb-3 pt-4",
                                  attrs: { align: "center" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() => {
                                        _vm.addingStore = true
                                      }).apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "store-add-logo",
                                    attrs: { sm1: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "store",
                                      attrs: { sm11: "" },
                                    },
                                    [_vm._v("Add Store")]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0 pt-1", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "label pa-3",
                      attrs: { cols: "12", sm: "5" },
                    },
                    [_vm._v("Permissions")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "header", attrs: { cols: "12", sm: "2" } },
                    [_vm._v("Edit")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "header", attrs: { cols: "12", sm: "2" } },
                    [_vm._v("View")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "header", attrs: { cols: "12", sm: "2" } },
                    [_vm._v("No Access")]
                  ),
                ],
                1
              ),
              _vm._l(
                Object.entries(_vm.permissions),
                function ([permission, access]) {
                  return _c(
                    "v-row",
                    {
                      key: permission,
                      staticClass: "form-border-bottom mt-0 pt-0",
                      attrs: { align: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label__light pa-3 ml-5",
                          attrs: { cols: "12", sm: "4" },
                        },
                        [_vm._v(_vm._s(_vm.displayForPermission(permission)))]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "header",
                          attrs: { cols: "12", sm: "2" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "#93BD20",
                              "input-value": access == _vm.Access.EDIT,
                              value: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setEdit(permission)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "header",
                          attrs: { cols: "12", sm: "2" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "#93BD20",
                              "input-value": access == _vm.Access.VIEW,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setView(permission)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "header",
                          attrs: { cols: "12", sm: "2" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "#93BD20",
                              "input-value": access == _vm.Access.NONE,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setNoAccess(permission)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }