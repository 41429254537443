<template>
  <div class="text-button d-flex align-items-center">
    <div
      id="dontGrow"
      v-if="type !== 'normal'"
      :class="[`text-button__icon--${type}`]"
      class="text-button__icon"
    />

    <div :class="[`text-button__text--${type}`]" class="text-button__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
    name: "TextButton",
    props: {
        type: {
            type: String,
            default: () => "normal"
        }
    }
};
</script>

<style lang="scss">
@import "~scss/mixins";
@import "~scss/variables";

.text-button {
  cursor: pointer;
  &__text{
    font-size: 14px;
  }
  &__icon {
    width: spacing(sm);
    height: spacing(sm);
    &--delete {
      @include background-image("", "trash_red.svg");
    }

    &--add {
      @include background-image("", "mod_add.svg");
    }
  }

  &__text {
    &--delete {
      color: $delete_red;
    }

    &--add, &--green {
      color: $brand_color;
    }
  }
}
</style>
