<template>
  <EditingModal size="sm">
    <AlertDialog title="Edit Store" :onExit="close" :actions="actions">
      <v-container class="pa-0">
        <v-row   align="center" class="form-border-bottom mt-0 pb-3 pt-0">
          <v-col cols="12" md="4" class="label pa-3">Store</v-col>
          <v-col cols="12" md="8">{{`${store.storeName} (${store.airportIdent})`}}</v-col>
        </v-row>
        <v-row class="mt-0 pt-1"   align="center">
          <v-col cols="12" md="5" class="label pa-3">Permissions</v-col>
          <v-col cols="12" md="2" class="header">Edit</v-col>
          <v-col cols="12" md="2" class="header">View</v-col>
          <v-col cols="12" md="2" class="header">No Access</v-col>
        </v-row>
        <v-row
          
          
          align="center"
          class="form-border-bottom mt-0 pt-0"
          v-for="[permission, access] in Object.entries(permissions)"
          :key="permission"
        >
          <v-col cols="12" md="4" class="label__light pa-3 ml-5">{{displayForPermission(permission)}}</v-col>
          <v-col cols="12" md="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.EDIT"
              value
              @click.stop="setEdit(permission)"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.VIEW"
              @click.stop="setView(permission)"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2" class="header">
            <v-checkbox
              color="#93BD20"
              :input-value="access == Access.NONE"
              @click.stop="setNoAccess(permission)"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </AlertDialog>
  </EditingModal>
</template>

<script>
import EditingModal from "components/editing_modal";
import AlertDialog from "components/alert_dialog";
import { Permissions, Access } from "helpers/access";
import clone from "clone";

export default {
    name: "EditStoreModal",
    components: {
        EditingModal,
        AlertDialog
    },
    props: {
        store: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            row: "",
            actions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: this.close
                },
                {
                    type: "proceed-green",
                    display: "Save",
                    run: this.save
                }
            ],
            permissions: {},
            Access: Access,
            Permissions: Permissions
        };
    },
    mounted() {
        let allPermissions = Object.fromEntries(Object.entries(Permissions).map(([key, _]) => {
            return [key, Access.NONE]
        }))
        var existingPermissions = clone(this.store.permissions);
        for (let key in allPermissions) {
            if (existingPermissions[key] === undefined) {
                existingPermissions[key] = Access.NONE
            }
        }

        this.permissions = existingPermissions
    },
    methods: {
        close() {
            this.$emit("close");
        },
        save() {
            this.$emit("save", {
                ...this.store,
                permissions: this.permissions
            });
        },
        accessForPermission(key) {
            return this.permissions[key];
        },
        displayForPermission(key) {
            return this.Permissions[key]
        },
        setNoAccess(permission) {
            this.$set(this.permissions, permission, Access.NONE);
        },
        setView(permission) {
            this.$set(this.permissions, permission, Access.VIEW);
        },
        setEdit(permission) {
            this.$set(this.permissions, permission, Access.EDIT);
        }
    }
};
</script>

<style lang="scss" scoped>
.form-border-bottom:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.label {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;

  &__light {
    font-weight: normal;
  }
}
</style>
