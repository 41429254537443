// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/trash_red.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../img/mod_add.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".text-button {\n  cursor: pointer;\n}\n.text-button__text {\n  font-size: 14px;\n}\n.text-button__icon {\n  width: 18px;\n  height: 18px;\n}\n.text-button__icon--delete {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n.text-button__icon--add {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n}\n.text-button__text--delete {\n  color: #ff1744;\n}\n.text-button__text--add, .text-button__text--green {\n  color: #93BD20;\n}", ""]);
// Exports
module.exports = exports;
